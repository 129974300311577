import { useAsyncValue } from "react-router";
import { tooltipContent } from "../../content";
import { RoomsOverviewSummaryDTO } from "../../model/types";
import SummaryCard from "../SummaryCard/SummaryCard";
import { convertSecondsToHoursMinutesSeconds } from "../../utils";

function Summary() {
  const summary = useAsyncValue() as RoomsOverviewSummaryDTO;
  const { averages } = summary;
  if (!averages) {
    console.error("Averages not found in summary");
    return null;
  }

  return (
    <>
      <div className="ni-visit-overview-summary-card" key={0}>
        <SummaryCard
          index={0}
          height="100%"
          contentClassName="position-absolute"
          title="Percentage of Time Patient was Alone"
          data={summary.averages.timeAlone + "%"}
          tooltipContent={tooltipContent.roomsOverview.timeAlone["en-US"]}
        />
      </div>
      <div className="ni-visit-overview-summary-card" key={1}>
        <SummaryCard
          index={1}
          title="Average Length of Time Between Visits"
          data={convertSecondsToHoursMinutesSeconds(
            summary.averages.timeBetween
          )}
          tooltipContent={tooltipContent.roomsOverview.timeBetween["en-US"]}
        />
      </div>
      <div className="ni-visit-overview-summary-card" key={2}>
        <SummaryCard
          height="100%"
          contentClassName="position-absolute"
          index={2}
          title="Average Length of Visit"
          data={convertSecondsToHoursMinutesSeconds(
            summary.averages.visitLength
          )}
          tooltipContent={tooltipContent.roomsOverview.visitLength["en-US"]}
        />
      </div>
    </>
  );
}

export default Summary;
